var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.deleteParcipantModalData.showModal ? _c('BaseModal', {
    on: {
      "close": function close($event) {
        _vm.deleteParcipantModalData.showModal = false;
      }
    }
  }, [_c('BaseConfirmModalForm', {
    attrs: {
      "modalBaseData": _vm.deleteParcipantModalData
    },
    on: {
      "submit": function submit($event) {
        return _vm.deleteParticipant(_vm.deleteParcipantModalData.existingItem);
      },
      "close": function close($event) {
        _vm.deleteParcipantModalData.showModal = false;
      }
    }
  }, [_vm._v(" Er du sikker på at du vil fjerne " + _vm._s(_vm.isVocationalSchool ? "student" : "deltaker") + " fra kurset? ")])], 1) : _vm._e(), _vm.showModal ? _c('BaseModal', {
    on: {
      "close": _vm.closeModal
    }
  }, [_c('UpsertParticipantModal', {
    attrs: {
      "headline": _vm.modalHeadline,
      "type": _vm.modalType,
      "memberOrganizations": _vm.orderedMemberOrganizations || [],
      "courseDefaultOptions": _vm.courseDefaultOptions,
      "activeUsers": _vm.activeUsers,
      "isCourseDone": _vm.isCourseDone,
      "selectedUserId": _vm.selectedUserId,
      "chosenMemberOrgId": _vm.chosenMemberOrgId,
      "currentCourseId": _vm.courseId,
      "subOrganizerId": _vm.course.subOrganizerOrganizationId || 0,
      "activeFeatureSettings": _vm.activeFeatureSettings
    },
    on: {
      "close": _vm.closeModal,
      "updateParticipantsTable": _vm.getCourseParticipants
    }
  })], 1) : _vm._e(), _vm.course.enrollmentDeadline ? _c('div', {
    staticClass: "small-header font-weight-light subtitle-1"
  }, [_vm._v(" " + _vm._s("".concat(_vm.isVocationalSchool ? "Søknadsfrist:" : "Påmeldingsfrist:", " ").concat(_vm.formatDateShort(_vm.course.enrollmentDeadline))) + " ")]) : _vm._e(), _c('v-form', [_c('BaseTableFiltered', {
    directives: [{
      name: "sortable-data-table",
      rawName: "v-sortable-data-table"
    }],
    attrs: {
      "headers": _vm.headers,
      "items": _vm.courseStudents,
      "search": _vm.search,
      "filter": _vm.filter,
      "item-key": "userId",
      "no-data-text": "Ingen deltakere på kurset"
    },
    on: {
      "sorted": _vm.sorted
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title font-weight-light text-center",
          staticStyle: {
            "align-self": "center"
          },
          attrs: {
            "data-cy": "participantsHeader"
          }
        }, [_vm._v(" " + _vm._s(_vm.isVocationalSchool ? "Studenter" : "Deltakere") + " ")]), _c('v-spacer'), _c('v-text-field', {
          attrs: {
            "append-icon": "mdi-magnify",
            "label": _vm.isVocationalSchool ? 'Søk blant studenter' : 'Søk blant deltakere',
            "single-line": ""
          },
          model: {
            value: _vm.search,
            callback: function callback($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        }), !_vm.hasMemberOrgAccess ? _c('v-btn', {
          staticClass: "mx-2",
          attrs: {
            "color": "primary",
            "disabled": _vm.isCourseDone
          },
          on: {
            "click": function click($event) {
              return _vm.updateParticipantRank(_vm.courseStudents);
            }
          }
        }, [_vm._v(" Oppdater rangering ")]) : _vm._e(), !_vm.hasMemberOrgAccess && !_vm.isCourseDone ? _c('BaseTooltipIconButton', {
          attrs: {
            "color": "primary",
            "btnText": _vm.isVocationalSchool ? 'Legg til studenter' : 'Legg til deltakere',
            "icon": "mdi-plus"
          },
          on: {
            "handleClick": _vm.newParticipant
          }
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "data-cy": "viewParticipant-".concat(item.userId)
                },
                on: {
                  "click": function click($event) {
                    return _vm.onepager(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-open-in-new ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v(_vm._s(_vm.isVocationalSchool ? "Vis student" : "Vis deltaker"))])]), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on,
                  attrs = _ref3.attrs;
              return [_c('span', _vm._g(_vm._b({}, 'span', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "data-cy": "editParticipant",
                  "disabled": !_vm.canEditParticpantCheck
                },
                on: {
                  "click": function click($event) {
                    return _vm.editParticipant(item);
                  }
                }
              }, [_vm._v(" mdi-pencil ")])], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v(_vm._s(_vm.textTooltip))])]), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var on = _ref4.on,
                  attrs = _ref4.attrs;
              return [_c('span', _vm._g(_vm._b({}, 'span', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "disabled": item.status !== 'avmeldt'
                },
                on: {
                  "click": function click($event) {
                    return _vm.openDeleteParticpant(item);
                  }
                }
              }, [_vm._v(" mdi-trash-can ")])], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v(_vm._s(item.status !== "avmeldt" ? "".concat(_vm.isVocationalSchool ? "Student" : "Deltaker", " har ikke status avmeldt") : "Fjern ".concat(_vm.isVocationalSchool ? "student" : "deltaker", " fra kurset")))])])];
      }
    }, {
      key: "item.latestCourseParticipantComment",
      fn: function fn(_ref5) {
        var value = _ref5.value;
        return [value ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref6) {
              var on = _ref6.on,
                  attrs = _ref6.attrs;
              return [_c('v-icon', _vm._g(_vm._b({}, 'v-icon', attrs, false), on), [_vm._v("mdi-message")])];
            }
          }], null, true)
        }, [_c('span', {
          domProps: {
            "innerHTML": _vm._s(value)
          }
        })]) : _vm._e()];
      }
    }, {
      key: "item.userTypeType",
      fn: function fn(_ref7) {
        var value = _ref7.value;
        return [_vm._v(" " + _vm._s(value === "b2c" ? "Minside" : "Begrenset") + " ")];
      }
    }, {
      key: "item.status",
      fn: function fn(_ref8) {
        var value = _ref8.value;
        return [_vm._v(" " + _vm._s(value.charAt(0).toUpperCase() + value.slice(1)) + " ")];
      }
    }, {
      key: "item.currentAttendancePercentage",
      fn: function fn(_ref9) {
        var value = _ref9.value;
        return [_vm._v(" " + _vm._s(value ? "".concat(value, "%") : "-") + " ")];
      }
    }, {
      key: "item.rank",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_vm._v(" " + _vm._s(_vm.ranking(item)) + " ")];
      }
    }, {
      key: "item.updated",
      fn: function fn(_ref11) {
        var value = _ref11.value;
        return [_vm._v(" " + _vm._s(_vm.formatDateTime(value)) + " ")];
      }
    }, {
      key: "item.userFullName",
      fn: function fn(_ref12) {
        var value = _ref12.value;
        return [_vm._v(" " + _vm._s(value) + " ")];
      }
    }, {
      key: "item.memberOrganizationId",
      fn: function fn(_ref13) {
        var value = _ref13.value;
        return [_vm._v(" " + _vm._s(_vm.getMemberOrganizationNameById(value)) + " ")];
      }
    }, {
      key: "item.requiresElectronicInvoice",
      fn: function fn(_ref14) {
        var value = _ref14.value;
        return [_vm._v(" " + _vm._s(value ? "Ja" : "Nei") + " ")];
      }
    }, {
      key: "item.appliedViaForm",
      fn: function fn(_ref15) {
        var value = _ref15.value;
        return [_vm._v(" " + _vm._s(value ? "Søkt gjennom web" : "Lagt til av kursadmin") + " ")];
      }
    }], null, true)
  }, [_c('v-spacer')], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }