
import ParticipantsTable from "@/components/course/details/ParticipantsTable.vue";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import { useNavigateBack } from "@/shared/helpers/navigationHelpers";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent } from "@vue/composition-api";
export default defineComponent({
  name: "CourseParticipantsPage",
  components: { ParticipantsTable, BaseLayout },
  setup() {
    const store = useStore<StoreState>();

    const navigateBack = () => useNavigateBack(SingleCourseRouteNames.CourseDashboard);

    return { navigateBack, course: computed(() => store.state.courses.course) };
  },
});
